import { createSlice, current } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils

// ----------------------------------------------------------------------

type InitialState = {
  selectedDate: Date;
};
const initialState: InitialState = {
  selectedDate: new Date()
};

const slice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    // START LOADING

    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function setUpdatedSelectedDate(date: any) {
  return async () => {
    dispatch(slice.actions.setSelectedDate(date));
  };
}

// ----------------------------------------------------------------------
