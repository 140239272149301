import { map, filter, remove } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { EventInput } from '@fullcalendar/common';
import { dispatch } from '../store';
// utils
import { addDays } from 'date-fns';
import lodash from 'lodash';
import { axios } from '../../utils/axios';
//
import { CalendarState } from '../../@types/calendar';
// import { KanbanCard, KanbanColumn } from '../../@types/kanban';
function objFromArray<Type extends Record<string, any>>(array: Type[], key: string = 'id') {
  return array.reduce<Record<string, Type>>((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}
const now = new Date();

// ----------------------------------------------------------------------
export type Resource = {
  driverID: number;
  avatar: string;
  name: string;
};
export type Vehicles = {
  registrationPlate: String;
  avatar: string;
  name: string;
};
export type Slots = {
  slotName: string;
  data: SchedularJob[];
};
export type SchedularJob = {
  client: string;
  clientAddress: string;
  docketType: string;
  driverID: number;
  driverName: string;
  hazardous: boolean;
  jobID: number;
  jobStatus: number;
  dayType: string;
  serviceDate: string;
  occurrenceFrom: string;
  occurrenceTo: string;
  serviceDescription: string;
  serviceType: string;
  slotOrder: string;
  vehicleRegistration: string;
  vehicleType: string;
  wasteDetail: string;
};
export type Drivers = {
  driverID: string;
  driverName: string;
};
export type VehiclesData = {
  registrationPlate: string;
  vehicleType: string;
};

type InitialState = {
  isLoading: boolean;
  error: boolean;
  board: {
    resources: Resource[];
    vehicles: Vehicles[];
    jobs: SchedularJob[];
    slots: Slots[];
    // cards: KanbanCard[];
    // columns: Record<string, KanbanColumn>;
    columnOrder: string[];
    allDrivers: any[];
    allVehicles: any[];
  };
};
const initialState: InitialState = {
  isLoading: false,
  error: false,
  board: {
    vehicles: [],
    resources: [],
    jobs: [],
    slots: [],
    // cards: [],
    // columns: {},
    columnOrder: [],
    allDrivers: [],
    allVehicles: []
  }
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    resetBoard(state) {
      console.log('reset called');
      state.board = {
        vehicles: [],
        resources: [],
        jobs: [],
        slots: [],
        // cards: [],
        // columns: {},
        columnOrder: [],
        allDrivers: state.board.allDrivers,
        allVehicles: state.board.allVehicles
      };
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state) {
      state.isLoading = false;
      // const board = action.payload;
      // const resources = state.board.resources;
      // const jobs = state.board.jobs;
      // const slots = state.board.slots;
      // const cards = board.cards;
      // const columns = objFromArray<KanbanColumn>(board.columns);
      // const { columnOrder } = board;
      // state.board = {
      //   resources,
      //   jobs,
      //   slots,
      //   cards,
      //   columns,
      //   columnOrder
      // };
    },
    // set drivers
    setResources(state, action) {
      const resourceList = action.payload;
      state.board.resources = resourceList;
    },
    setJobs(state, action) {
      const jobList = action.payload.jobs;
      state.board.jobs = jobList;
      state.board.vehicles = action.payload.vehicles;
      console.log(jobList, 'slotData');
    },

    setBoardvehicles(state) {
      const vehicles = current(state.board.vehicles);
      let jobs = current(state.board.jobs);
      let slots: any = [];
      const result = Object.values(
        jobs.reduce((r: any, { vehicleRegistration }) => {
          r[vehicleRegistration] ??= { vehicleRegistration, count: 0 };
          r[vehicleRegistration].count++;
          return r;
        }, {})
      );
      const value = Math.max.apply(
        Math,
        result.map(function (o: any) {
          return o.count;
        })
      );

      // slots
      let foundJobsData: any = [];
      for (let i = 0; i < value; i++) {
        let slotData = vehicles.map((vehicles) => {
          const foundJob = jobs.find(
            (job) =>
              !foundJobsData.includes(job) && job.vehicleRegistration == vehicles.registrationPlate
          );
          if (foundJob) {
            foundJobsData.push(foundJob);
            return foundJob;
          } else {
            return {
              client: '',
              clientAddress: '',
              docketType: '',
              driverID: 0,
              driverName: '',
              hazardous: false,
              jobID: 0,
              dayType: 0,
              jobStatus: 0,
              serviceDate: '',
              serviceDescription: '',
              serviceType: '',
              slotOrder: '',
              vehicleRegistration: '',
              vehicleType: '',
              wasteDetail: '',
              occurrenceFrom: '',
              occurrenceTo: ''
            };
          }
        });

        slots.push({
          slotName: i,
          data: slotData
        });

        // dispatch(slice.actions.getBoardSuccess());
      }
      state.board.slots = slots;
      state.isLoading = false;
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {},

    persistCard(state, action) {
      const columns = action.payload;
      // state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      // state.board.cards[card.id] = card;
      // state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      // state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter(
      //   (id) => id !== cardId
      // );
      // state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {},

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {},
    setDrivers(state, action) {
      state.board.allDrivers = action.payload;
    },
    setVehicles(state, action) {
      state.board.allVehicles = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

export function getVehiclesDrivers() {
  return async () => {
    try {
      const response: any = await axios.get(`/rest/api/driver/all`);
      const allDrivers = response.data.data.filter((item: any) => item.driverID !== -1);
      // state.board.allDrivers = allDrivers;
      dispatch(slice.actions.setDrivers(allDrivers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    try {
      const response: any = await axios.get(`/rest/api/vehicle/all`);
      const allVehicles = response.data.data.filter((item: any) => item.driverID !== -1);
      // state.board.allDrivers = allDrivers;
      dispatch(slice.actions.setVehicles(allVehicles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getBoard(date: any) {
  return async () => {
    dispatch(slice.actions.resetBoard());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/rest/api/job/all/2?currentDate=${date}`);
      if (response.data.success == false) {
        console.log('no data found');
        dispatch(slice.actions.stopLoading());
      } else {
        dispatch(slice.actions.setJobs(response.data.data));
        try {
          console.log('ok', 'slotData');
          dispatch(slice.actions.setBoardvehicles());
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn: { name: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/new', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function updateColumn(columnId: string, updateColumn: KanbanColumn) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/kanban/columns/update', {
//         columnId,
//         updateColumn
//       });
//       dispatch(slice.actions.updateColumnSuccess(response.data.column));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function deleteColumn(columnId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/kanban/columns/delete', { columnId });
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder: string[]) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

export function deleteTask({ cardId, columnId }: { cardId: string; columnId: string }) {
  return () => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}
