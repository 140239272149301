// set axios interceptors to attach token to every request made to a specific domain only

import axios from 'axios';
// import { userToken } from './constants';
// import {UserTokenData} from '../Contexts/userContext'
import { API_URL } from './constants';
const instance = axios.create({
  baseURL: `${API_URL}/`
});

instance.interceptors.request.use(function (config) {
  return config;
});

export { instance as axios };
